<template>
  <div>
    <div class="row">
      <div class="col-md-12 d-flex flex-column">
        <h5 class="text-uppercase">{{ title }}</h5>
        <p v-html="text"></p>
        <span v-if="fuente">{{ fuente }}</span>
        <img
          v-if="fuenteImg"
          :src="getImage(fuenteImg, 'links')"
          class="img-fluid w80 pb-3"
        />
      </div>
    </div>
    <div class="row d-flex justify-content-center mb-5">
      <img
        v-if="img"
        :src="getImage(img, 'articulos')"
        class="img-fluid foto"
      />
    </div>
    <h6 class="txt-gris bold" v-if="productos" >{{ $t('articulos.productosRecomendados') }}</h6>
    <div class="row recomendados" v-if="productos" :class="{smallrecomendados: shrinkRecomendados}">
      <CardsRecomendados :productos="productos" :customSettings="customSettings"/>
    </div>
  </div>
</template>

<script>
import CardsRecomendados from "@/components/CardsRecomendados.vue";
export default {
  name: "Articulo",
  components: {
    CardsRecomendados,
  },
  props: {
    title: String,
    text: String,
    img: String,
    fuente: String,
    fuenteImg: String,
    productos: Array,
    customSettings: Object,
    shrinkRecomendados: Boolean,
  },
  methods: {
    getImage(fileName, folder) {
      return require("../assets/images/" + folder + "/" + fileName);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/constants.scss";
.recomendados {
  max-width: 80%;
  margin: 0 auto;
}
.smallrecomendados {
  max-width: 450px !important;
}

.foto {
  max-width: 400px;
}

@media (max-width: 425px) {
}
</style>
