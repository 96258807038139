<template>
  <div class="mt-5" id="envejeciendoBien">
    <div class="container" ref="musculos-y-proteinas">
      <Articulo
        :title="$t('articulos.3.a.titulo')"
        :text="$t('articulos.3.a.texto')"
        fuenteImg=""
        img=""
        :productos="[
          'sustenta-neutro',
          'sustenta-vainilla',
          'lactoproteyn',
          'sustenta-db-neutro',
          'sustenta-db-vainilla',
        ]"
      />
    </div>
    <div class="container" ref="neuronutrientes">
      <Articulo
        :title="$t('articulos.3.b.titulo')"
        :text="$t('articulos.3.b.texto')"
        fuenteImg=""
        img=""
        :productos="[
          'sustenta-neutro',
          'sustenta-semielemental',
          'sustenta-vainilla',
          'sustenta-db-neutro',
          'sustenta-fibras',
          'sustenta-db-vainilla',
        ]"
      />
    </div>
  </div>
</template>

<script>
import Articulo from "@/components/Articulo.vue";
export default {
  name: "EnvejeciendoBien",
  components: {
    Articulo,
  },
  data() {
    return {
      hash: this.$route.hash,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace("#", "");
        this.scrollToAnchorPoint(refName);
      }
    });
  },
  methods: {
    getImage(fileName) {
      return require("../../assets/images/carousel/" +
        this.$i18n.locale +
        "/" +
        fileName);
    },
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName];
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>