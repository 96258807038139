var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5",attrs:{"id":"envejeciendoBien"}},[_c('div',{ref:"musculos-y-proteinas",staticClass:"container"},[_c('Articulo',{attrs:{"title":_vm.$t('articulos.3.a.titulo'),"text":_vm.$t('articulos.3.a.texto'),"fuenteImg":"","img":"","productos":[
        'sustenta-neutro',
        'sustenta-vainilla',
        'lactoproteyn',
        'sustenta-db-neutro',
        'sustenta-db-vainilla' ]}})],1),_c('div',{ref:"neuronutrientes",staticClass:"container"},[_c('Articulo',{attrs:{"title":_vm.$t('articulos.3.b.titulo'),"text":_vm.$t('articulos.3.b.texto'),"fuenteImg":"","img":"","productos":[
        'sustenta-neutro',
        'sustenta-semielemental',
        'sustenta-vainilla',
        'sustenta-db-neutro',
        'sustenta-fibras',
        'sustenta-db-vainilla' ]}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }